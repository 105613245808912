import React, { useEffect, useState } from 'react'
import Featured from './Featured';
import AutoplaySwiper from './AutoplaySwiper';
import logo from '../img/logo.png';

export interface SplashScreenProps {
    featured: {
        id: number;
        //nomEsp: string;
        descEsp: string;
        //nomEng: string;
        descEng: string;
    }[],
    lang: "eng" | "esp",
    height: number
}

const SplashScreen = (props: SplashScreenProps) => {
    const [swiperIndex, setSwiperIndex] = useState(0);

    useEffect(() => {
        //console.log('Elemento activo: ' + swiperIndex);
    })

    return (
        <div className='splashscreen' style={{ height: props.height }}>
            {/*<div className='logo'>
                <img src={logo} alt='' />
            </div>*/}
            <Featured swiperIndex={swiperIndex} />
            <AutoplaySwiper splashscreen={props} setSwiperIndex={setSwiperIndex} />
        </div>
    );
}

export default SplashScreen;
