import Swiper from "swiper"
import { ICategory } from "../interfaces/interfaces"
import MenuElement from "./MenuElement"

const MenuList = ({ menu, swiperInstance, language, categoriesHeight }: { menu: ICategory[], swiperInstance: Swiper, language: "eng" | "esp", categoriesHeight: number }) => {

    const goTo = (number: number) => {
        swiperInstance.slideTo(number)
    }

    return <>{
        menu.map((menuElement, index) => (
            <MenuElement data={menuElement} index={index} lang={language} goTo={goTo} categoriesHeight={categoriesHeight} key={menuElement.name}></MenuElement>
        ))
    }</>
}
export default MenuList