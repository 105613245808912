import { useEffect, useMemo, useRef, useState } from "react"
import { ICategory } from "../interfaces/interfaces"

const MenuElement = ({ data, lang, index, goTo, categoriesHeight }: { data: ICategory, index: number, lang: "esp" | "eng", goTo: (section: number) => void, categoriesHeight: number }) => {
    const [isVisible, setIsVisible] = useState(false)
    const containerRef = useRef(null)
    const [name, setName] = useState("")
    useEffect(() => {
        if (lang === "esp") {
            setName(data.translations.nomEsp)
        } else {
            setName(data.translations.nomEng)
        }
    }, [lang, data, index])

    const options = useMemo(() => {
        return {
            root: null,
            rootMargin: "0px",
            threshold: 1.0
        }
    }, [])
    useEffect(() => {
        const callbackFunction: IntersectionObserverCallback = (entries) => {
            const [entry] = entries
            if (entry.isIntersecting) {
                goTo(Math.floor(index / 6))
            }
            setIsVisible(entry.isIntersecting)
        }
        const observer = new IntersectionObserver(callbackFunction, options)
        if (containerRef.current) observer.observe(containerRef.current)
        return () => {
            if (containerRef.current) observer.unobserve(containerRef.current)
        }
    }, [containerRef, options, index, name])

    return <div ref={containerRef} style={{ position: "relative" }} >
        <h3 className="category-name pt-3 fs-italic">{name}</h3>
        <div id={data.name.replace(/ /g, "-")} className="hidden-anchor" style={{ visibility: "hidden", position: "absolute", top: categoriesHeight * -1 }}>{data.name}</div>
        <div className={`img-${data.name.replace(/ /g, "-")} category-img mx-3`}></div>
        <ul className="px-3 menu-home-ul">
            {data.items.sort((item1, item2) => {
                return parseInt(item1.nomEsp.split(".")[0]) < parseInt(item2.nomEsp.split(".")[0]) ? -1 : 1
            }).map((item, i) => (
                <li className="my-2 category-element" key={lang === "esp" ? item.nomEsp : item.nomEng}>
                    <p className="fw-bold">
                        {lang === "esp" ? item.nomEsp : item.nomEng}
                    </p>
                    <p className="fst-italic">
                        {lang === "esp" ? item.descEsp : item.descEng}
                    </p>
                </li>
            ))}
        </ul>
    </div>
}
export default MenuElement