import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import { getFirestore } from "firebase/firestore";
import "./style.css";
import "./styles/CategoriesSlider.css";
import "./styles/MenuList.css";

// import NavBar from "./components/NavBar";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import Form from "./components/Form";
import Legal from "./components/Legal";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDHEIbrVL0z4IpqvcnSLVHDc4exTLcxxJE",
  authDomain: "in-volo-service.firebaseapp.com",
  projectId: "in-volo-service",
  storageBucket: "in-volo-service.appspot.com",
  messagingSenderId: "712161152888",
  appId: "1:712161152888:web:2a0fd0376e24394124828d",
  measurementId: "G-7V43MCNG37"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app)
class App extends React.Component {
    render() {
        return (
            <Fragment>
                <BrowserRouter>
                    {/* <NavBar /> */}
                    <Routes>
                        <Route path="/" element={<Home db={db}/>} />
                        <Route path="/legal" element={<Legal />} />
                        {/* <Route path="/form" element={<Form db={db}/>} /> */}
                    </Routes>
                </BrowserRouter>
            </Fragment>
        )
    }
}

ReactDOM.render(
    <App />,
    document.getElementById('root')
)