import { Fragment } from 'react';

interface FeaturedProps {
    swiperIndex: number
}

const Featured = (props: FeaturedProps) => {
    return (
        <Fragment>
            {/* Validamos en que índice se encuentra para mostrar una imagen determinada */}
            {props.swiperIndex === 0 ? (
                <div className='splashAll splashImg-0'></div>
            ) : props.swiperIndex === 1 ? (
                <div className='splashAll splashImg-1'></div>
            ) : (
                <div className='splashAll splashImg-2'></div>
            )}
        </Fragment>
    );
}

export default Featured;
