import { useEffect } from "react"
import { ITranslation } from "../interfaces/interfaces"
interface CategoryLinkProps {
    category: string
    children: JSX.Element | string
    data?: ITranslation
    lang: "eng" | "esp"
}

const CategoryLink = ({ children, category, data, lang }: CategoryLinkProps) => {

    useEffect(() => {
        //console.log(data, "CATEGORY LINK")
    })

    return (
        <li className="mt-1" >
            <a className="category-link" href={`#${category}`}>{data === undefined ? children : lang === "eng" ? data?.nomEng : data?.nomEsp}{ }</a>
        </li>
    )
}
export default CategoryLink;