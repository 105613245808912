import { Fragment, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Legal = () => {
    const lang = useLocation().state as string;
    const navigate = useNavigate();

    return (
        <Fragment>
            <div id="legal-content" className="legal p-3">
                {(lang === "esp" || lang === null) ? (<>
                    <div className="row">
                        <div className="col-3 d-flex justify-content-center">
                            <button className="botonAtras pe-sm-5" onClick={() => navigate(-1)}><i className="fa-solid fa-angle-left"></i></button>
                        </div>
                        <h1 className="col text-center py-3">Aviso Legal</h1>
                        <div className="col-3"></div>
                    </div>
                    <div className="pb-4">
                        <h2>Identificación y Titularidad</h2>
                        <p>En cumplimiento del artículo 10 de la Ley 34 / 2002, de 11 de julio, de Servicios de la Sociedad de la Información y Comercio Electrónico,
                            el Titular expone sus datos identificativos.</p>
                        <ul>
                            <li>Titular: In Volo Service S. L.</li>
                            <li>CIF: B10810968</li>
                            <li>Domicilio: Calle Gorgos 9 -32, Valencia</li>
                            <li>Correo electrónico: involoservicesl@gmail.com</li>
                            <li><a className="legal-link" href="https://involoservice.com/">https://involoservice.com/</a></li>
                        </ul>
                    </div>
                    <div className="pb-4">
                        <h2>Finalidad</h2>
                        <p>La finalidad del sitio Web <a className="legal-link" href="https://involoservice.com/">https://involoservice.com/</a> es proporcionar información sobre los diferentes productos ofrecidos por In Volo Service S. L., ubicado en Calle Gorgos 9 -32, Valencia. Toda la información proporcionada en este sitio es de carácter meramente informativo.</p>
                    </div>
                    <div className="pb-4">
                        <h2>Condiciones de Uso</h2>
                        <p>La utilización del sitio Web le otorga la condición de Usuario, e implica la aceptación completa de todas las cláusulas y condiciones de uso incluidas en la página:</p>
                        <ul>
                            <li>Aviso Legal</li>
                        </ul>
                        <p>Si no estuviera conforme con todas y cada una de estas cláusulas y condiciones absténgase de utilizar este sitio Web.</p>
                        <p>El acceso a este sitio Web no supone, en modo alguno, el inicio de una relación comercial con el Titular.</p>
                        <p>A través de este sitio Web, el Titular le facilita el acceso y la utilización de diversos contenidos que el Titular o sus colaboradores han publicadon por medio de Internet.</p>
                        <p>A tal efecto, usted está obligado y comprometido a NO utilizar cualquiera de los contenidos del sitio Web con fines o efectos ilícitos,
                            prohibidos en este Aviso Legal o por la legislación vigente, lesivos de los derechos e intereses de terceros, o que de cualquier forma puedan dañar, inutilizar,
                            sobrecargar, deteriorar o impedir la normal utilización de los contenidos, los equipos informáticos o los documentos, archivos y toda clase de contenidos almacenados en
                            cualquier equipo informático propios o contratados por el Titular, de otros usuarios o de cualquier usuario de Internet.</p>
                    </div></>) : (<>
                        <div className="row">
                            <div className="col-3 d-flex justify-content-center">
                                <button className="botonAtras pe-sm-5" onClick={() => navigate(-1)}><i className="fa-solid fa-angle-left"></i></button>
                            </div>
                            <h1 className="col text-center py-3">Legal</h1>
                            <div className="col-3"></div>
                        </div>
                        <div className="pb-4">
                            <h2>Identification and Ownership</h2>
                            <p>In compliance with article 10 of Law 34/2002, of July 11, on Services of the Information Society and Electronic Commerce,
                                the Holder exposes his identification data.</p>
                            <ul>
                                <li>Holder: In Volo Service S. L.</li>
                                <li>CIF: B10810968</li>
                                <li>Address: Calle Gorgos 9 -32, Valencia</li>
                                <li>Email: involoservicesl@gmail.com</li>
                                <li><a className="legal-link" href="https://involoservice.com/">https://involoservice.com/</a></li>
                            </ul>
                        </div>
                        <div className="pb-4">
                            <h2>Purpose</h2>
                            <p>The purpose of the website <a className="legal-link" href="https://involoservice.com/">https://involoservice.com/</a> is to provide information about the different products offered by In Volo Service S. L., located at Calle Gorgos 9 -32, Valencia. All information provided on this site is for informational purposes only.</p>
                        </div>
                        <div className="pb-4">
                            <h2>Terms of use</h2>
                            <p>The use of the website grants you the condition of User, and implies full acceptance of all the clauses and conditions of use included in the pages:</p>
                            <ul>
                                <li>Legal</li>
                            </ul>
                            <p>If you do not agree with each and every one of these clauses and conditions, refrain from using this website.</p>
                            <p>Access to this website does not imply, in any way, the beginning of a commercial relationship with the Owner.</p>
                            <p>Through this website, the Owner facilitates the access and use of various contents that the Owner or its collaborators have published through the Internet.</p>
                            <p>To this end, you are obliged and committed NOT to use any of the contents of the website for illicit purposes or effects,
                                prohibited in this Legal Notice or by current legislation, harmful to the rights and interests of third parties, or that in any way may damage, disable, overload,
                                deteriorate or prevent  the normal use of the content, computer equipment or documents, files and all kinds of content stored in
                                any computer equipment owned or contracted by the Owner, other users or any user of Internet.</p>
                        </div>
                    </>)}
            </div>
        </Fragment>
    )
}
export default Legal;