import React, { useRef, useState } from "react";
import {
	collection,
	Firestore,
	addDoc,
	getDocs,
	doc,
	getDoc,
} from "firebase/firestore";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "bootstrap/dist/css/bootstrap.css";
import { Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import CategoryLink from "./CategoryLink";
import { useEffect } from "react";
import SplashScreen from "./SplashScreen";
import {
	ICategory,
	IMenuElement,
	ITranslation,
} from "../interfaces/interfaces";
import MenuList from "./MenuList";
import UkFlag from "./svg/ukFlag"
import SpainFlag from "./svg/spainFlag"
import Footer from "./Footer";

const Home = ({ db }: { db: Firestore }) => {
	// TO-DO:
	//  -obtener categorias de la bd
	//  -pintar li con las categorias cada tres
	//  -al pinchar en la categoria, hacer scroll hasta la carta con esa id
	const [category, setCategory] = useState("ensaladas");
	const [language, setLanguage] = useState<"eng" | "esp">("esp");
	const [translationMenuData, setTranslationMenuData] = useState<ITranslation[]>([])
	const [splashHeight, setSplashHeight] = useState(400);
	const [categoriesHeight, setCategoriesHeight] = useState(170);
	const [menuData, setMenuData] = useState<
		{ name: string; items: any[]; translations: ITranslation }[]
	>([]);
	const featured = [
		{
			id: 0,
			//nomEsp: "Ensalada Capresse",
			descEsp: "Simplemente disfruta.",
			//nomEng: "Caprese salad",
			descEng: "Just enjoy.",
		},
		{
			id: 1,
			//nomEsp: "Ensalada César",
			descEsp: "En lo alto.",
			//nomEng: "Caesar salad",
			descEng: "In the sky.",
		},
		{
			id: 2,
			//nomEsp: "Ensalada Griega",
			descEsp: "La vida son momentos.",
			//nomEng: "Greek salad",
			descEng: "Life is moments.",
		},
	];

	useEffect(() => {
		console.log(menuData);
	}, [menuData]);

	const goTo = (category: string) => {
		setCategory(category);
	};
	const [swiperInstance, setSwiperInstance] = useState<any>(null);

	useEffect(() => {
		const fetchData = async () => {
			const categories = [
				"entradas",
				"ensaladas",
				"hamburguesas",
				"pokes bowl",
				"pescados",
				"carnes",
				"acompañamientos",
				"arroces",
				"cremas y sopas",
				"pasta",
				"salsas",
				"zumos naturales",
				"postres",
				"breakfast",
				"bebidas",
				"cervezas",
				"panaderia",
				"vinos",
				"kit decoracion cajitas",
				"tripulacion",
			];
			// const queryTranslations = await getDocs(collection(db, "traducciones"))
			// const translations = {}
			// queryTranslations.forEach((doc)=>{
			//     Object.assign(translations, doc.id, doc.data())
			// })
			// console.log(translations)

			const translations: ITranslation[] = []

			const menu = categories.map(async (category) => {
				const translationRef = doc(db, "traducciones", category);
				const translation = await getDoc(translationRef);
				const translationData = translation.data();
				translations.push(translationData as ITranslation)
				console.log(translation.data());
				let newCategory: ICategory = {
					name: category,
					translations: translationData as ITranslation,
					items: [],
				};
				const query = await getDocs(collection(db, category));
				query.forEach((doc) => {
					newCategory.items.push(doc.data() as IMenuElement);
				});
				return newCategory;
			});
			console.log(translations, "TRANSLATIONSSSSS")
			setTranslationMenuData(translations)
			return await Promise.all(menu);
		};
		fetchData().then((data) => setMenuData(data));
	}, [db]);

	const categoriesHeightRef = useRef<HTMLDivElement>(null as null | HTMLDivElement)
	useEffect(() => {
		if (categoriesHeightRef.current && categoriesHeightRef.current.clientHeight && categoriesHeightRef != null) {

			// alert(categoriesHeightRef.current.clientHeight)
			setCategoriesHeight(categoriesHeightRef.current.clientHeight)
			setSplashHeight(window.innerHeight - categoriesHeightRef.current.clientHeight)
		}
	}, [categoriesHeightRef, categoriesHeightRef.current?.clientHeight, menuData]);

	return (
		<div id="main-content">
			<SplashScreen featured={featured} lang={language} height={splashHeight} />
			<div className="container-fluid categories-slider d-flex flex-column" ref={categoriesHeightRef}>
				<div className="row-12 pt-2 d-flex flex-row">
					<div className="flex-grow-1">
						<h2>{language === "esp" ? "Nuestras Categorías" : "Our Categories"}</h2>
					</div>
					<div className="d-flex flex-row">
						<div className="mx-2 banderas" onClick={() => setLanguage("esp")}><SpainFlag /></div>
						<div className="mx-2 banderas" onClick={() => setLanguage("eng")}><UkFlag /></div>
					</div>
				</div>
				<div className="row flex-grow-1">
					<Swiper
						className="row"
						onSwiper={(swiper) => setSwiperInstance(swiper)}
						pagination={true}
						modules={[Pagination, Navigation]
						}
					>
						<SwiperSlide className="col-12">
							<div className="row pb-4">
								<ul className="col-6 menu-home-ul">
									<CategoryLink lang={language} category="entradas" data={translationMenuData[0]}>
										Entradas / Aperitivos
									</CategoryLink>
									<CategoryLink lang={language} category="ensaladas" data={translationMenuData[1]}>
										Ensaladas
									</CategoryLink>
									<CategoryLink lang={language} category="hamburguesas" data={translationMenuData[2]}>
										Hamburguesas
									</CategoryLink>
								</ul>
								<ul className="col-6 menu-home-ul">
									<CategoryLink lang={language} category="pokes-bowl" data={translationMenuData[3]}>
										Pokes Bowl
									</CategoryLink>
									<CategoryLink lang={language} category="pescados" data={translationMenuData[4]}>
										Pescados
									</CategoryLink>
									<CategoryLink lang={language} category="carnes" data={translationMenuData[5]}>
										Carne
									</CategoryLink>
								</ul>
							</div>
						</SwiperSlide>
						<SwiperSlide className="col-12">
							<div className="row">
								<ul className="col-6 menu-home-ul">
									<CategoryLink lang={language} category="acompañamientos" data={translationMenuData[6]}>
										Acompañamientos
									</CategoryLink>
									<CategoryLink lang={language} category="arroces" data={translationMenuData[7]}>
										Arroces
									</CategoryLink>
									<CategoryLink lang={language} category="cremas-y-sopas" data={translationMenuData[8]}>
										Cremas y Sopas
									</CategoryLink>
								</ul>
								<ul className="col-6 menu-home-ul">
									<CategoryLink lang={language} category="pasta" data={translationMenuData[9]}>
										Pasta
									</CategoryLink>
									<CategoryLink lang={language} category="salsas" data={translationMenuData[10]}>
										Salsas
									</CategoryLink>
									<CategoryLink lang={language} category="zumos-naturales" data={translationMenuData[11]}>
										Zumos Naturales
									</CategoryLink>
								</ul>
							</div>
						</SwiperSlide>
						<SwiperSlide className="col-12">
							<div className="row">
								<ul className="col-6 menu-home-ul">
									<CategoryLink lang={language} category="postres" data={translationMenuData[12]}>
										Postres (producción propia)
									</CategoryLink>
									<CategoryLink lang={language} category="breakfast" data={translationMenuData[13]}>
										Breakfast Artesanal
									</CategoryLink>
									<CategoryLink lang={language} category="bebidas" data={translationMenuData[14]}>
										Bebidas
									</CategoryLink>
								</ul>
								<ul className="col-6 menu-home-ul">
									<CategoryLink lang={language} category="cervezas" data={translationMenuData[15]}>
										Cervezas
									</CategoryLink>
									<CategoryLink lang={language} category="panaderia" data={translationMenuData[16]}>
										Panadería
									</CategoryLink>
									<CategoryLink lang={language} category="vinos" data={translationMenuData[17]}>
										Vinos
									</CategoryLink>
								</ul>
							</div>
						</SwiperSlide>
					</Swiper>
				</div>
			</div>
			<div className="container-fluid menu-list pb-4" id="menuList">
				<MenuList
					menu={menuData}
					swiperInstance={swiperInstance}
					language={language}
					categoriesHeight={categoriesHeight}
				></MenuList>
				{/* <Footer /> */}
			</div>
			<div className="container-fluid menu-list border-top-0" id="footer">
				<Footer lang={language}/>
			</div>
		</div>
	);
};

export default Home;
