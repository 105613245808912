import React, { useState } from 'react';
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import 'bootstrap/dist/css/bootstrap.css';
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { SplashScreenProps } from './SplashScreen';

interface AutoplaySwiperProps {
    splashscreen: SplashScreenProps,
    setSwiperIndex: React.Dispatch<React.SetStateAction<number>>,
}

const AutoplaySwiper = (props: AutoplaySwiperProps) => {
    // Habilitar AutoPlay
    SwiperCore.use([Autoplay]);
    const [swiperInstance, setSwiperInstance] = useState<any>(null);

    return (
        <div className='autoplayswiper'>
            <Swiper className='swiper-class py-2' onSwiper={(swiper) => setSwiperInstance(swiper)} pagination={true} modules={[Pagination, Navigation]} autoplay={{ delay: 5000, disableOnInteraction: false }} onActiveIndexChange={(index) => props.setSwiperIndex(index.activeIndex)}>
                {props.splashscreen.featured.map((featured) => (
                    <SwiperSlide className="container featuredSlider" key={featured.id} >
                        {/*<div className='featuredText p-2'>
                            {props.splashscreen.lang === "esp" ? featured.nomEsp : featured.nomEng}
                        </div>*/}
                        <div className='featuredText p-3 px-2'>
                            {props.splashscreen.lang === "esp" ? featured.descEsp : featured.descEng}
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
}

export default AutoplaySwiper;
