import React, { Fragment } from "react";
import { Link } from "react-router-dom";

interface FooterProps {
    lang: "eng" | "esp",
}

const Footer = (props: FooterProps) => {
    return (
        <Fragment>
            <div className="text-center px-3 py-5 footer">
                {/* <p className="mb-0">Copyright © 2022-23 | In Volo Service</p> */}
                <p className="mb-0">Valencia | Alicante</p>
                <p className="mb-0">involoservicesl@gmail.com</p>
                <a className="mb-0"><Link className="nav-link legal-link p-0" to="/legal" state={props.lang}>{props.lang === "esp" ? "Aviso legal" : "Legal"}</Link></a>
                {/* <p className="mb-0 d-flex justify-content-center"><a className="mb-0 a-phone" href="tel:+34622009869"><i className="bi bi-telephone-fill phone"></i> +(34) 622 009 869</a></p> */}
            </div>
        </Fragment>
    )
}
export default Footer;
